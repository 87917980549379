<template>
  <v-app :style="{background: $vuetify.theme.themes[$vuetify.theme.dark?'dark':'light'].backgroundLayouts}">
    <default-bar />

    <default-view />

    <default-footer />
  </v-app>
</template>

<script>
  export default {
    name: 'DefaultLayout',

    components: {
      DefaultBar: () =>
        import(
          /* webpackChunkName: "default-app-bar" */
          './AppBar'
        ),

      DefaultFooter: () =>
        import(
          /* webpackChunkName: "default-footer" */
          './Footer'
        ),

      DefaultView: () =>
        import(
          /* webpackChunkName: "default-view" */
          './View'
        ),
    },
  }
</script>
